import { createSlice } from "@reduxjs/toolkit";

type ApplicationState = {
  app_page: number;
  app_pronouns: string;
  app_country: string;
  app_city: string;
  app_age: string;
  app_discord: string;
  app_school: string;
  app_major: string;
  app_year: string;
  app_resume: File | null;
  app_resume_submitted: boolean;
  app_github: string;
  app_linkedIn: string;
  app_otherLinks: string;
  app_started: boolean;
  app_completed: boolean;
  app_tc_inPerson: boolean;
  app_tc_mlh: boolean;
  app_tc_subscribe: boolean;
  app_hackathon_attended: boolean;
  app_number_hackathon: number;
  app_parent_email: string;
  app_elevator_pitch: string;
  app_uofthacks_exp: string;
  app_learning_process: string;
  app_uofthacks_suggestions: string;
  quiz_q1: number;
  quiz_q2: number;
  quiz_q3: number;
  quiz_q4: number;
  quiz_q5: number;
  quiz_q6: number;
  character_name: string;
  redux_character_name: string;
};

type RSVPState = {
  attending: boolean;
  contact_name: string;
  contact_phone: string;
  contact_relation: string;
  dietry_restrictions: string[];
  merch_size: string;
  demographic: string[];
};

const initialStateRSVP: RSVPState = {
  attending: true,
  contact_name: "",
  contact_phone: "",
  contact_relation: "",
  dietry_restrictions: [],
  merch_size: "",
  demographic: [],
};

const initialState: ApplicationState = {
  app_page: 1,
  app_pronouns: "",
  app_country: "",
  app_city: "",
  app_age: "",
  app_discord: "",
  app_school: "",
  app_major: "",
  app_year: "",
  app_resume: null,
  app_resume_submitted: false,
  app_github: "",
  app_linkedIn: "",
  app_otherLinks: "",
  app_started: false,
  app_completed: false,
  app_tc_inPerson: false,
  app_tc_mlh: false,
  app_tc_subscribe: false,
  app_hackathon_attended: false,
  app_number_hackathon: 0,
  app_parent_email: "",
  app_elevator_pitch: "",
  app_uofthacks_exp: "",
  app_learning_process: "",
  app_uofthacks_suggestions: "",
  character_name: "",
  redux_character_name: "",
  quiz_q1: -1,
  quiz_q2: -1,
  quiz_q3: -1,
  quiz_q4: -1,
  quiz_q5: -1,
  quiz_q6: -1,
};

const rsvpSlice = createSlice({
  name: "rsvp",
  initialState: initialStateRSVP,
  reducers: {
    setAttending(state, action) {
      state.attending = action.payload;
    },
    setContactName(state, action) {
      state.contact_name = action.payload;
    },
    setContactPhone(state, action) {
      state.contact_phone = action.payload;
    },
    setContactRelation(state, action) {
      state.contact_relation = action.payload;
    },
    setDietryRestrictions(state, action) {
      state.dietry_restrictions = action.payload;
    },
    setMerchSize(state, action) {
      state.merch_size = action.payload;
    },
    setDemographic(state, action) {
      state.demographic = action.payload;
    },
    resetRSVP(state) {
      Object.assign(state, initialStateRSVP);
    },
  },
});

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setAppPage(state, action) {
      state.app_page = action.payload;
    },
    setAppPronouns(state, action) {
      state.app_pronouns = action.payload;
    },
    setAppCountry(state, action) {
      state.app_country = action.payload;
    },
    setAppCity(state, action) {
      state.app_city = action.payload;
    },
    setAppAge(state, action) {
      state.app_age = action.payload;
    },
    setAppDiscord(state, action) {
      state.app_discord = action.payload;
    },
    setAppSchool(state, action) {
      state.app_school = action.payload;
    },
    setAppMajor(state, action) {
      state.app_major = action.payload;
    },
    setAppYear(state, action) {
      state.app_year = action.payload;
    },
    setAppResume(state, action) {
      state.app_resume = action.payload;
    },
    setAppResumeSubmitted(state, action) {
      state.app_resume_submitted = action.payload;
    },
    setAppGithub(state, action) {
      state.app_github = action.payload;
    },
    setAppLinkedIn(state, action) {
      state.app_linkedIn = action.payload;
    },
    setAppOtherLinks(state, action) {
      state.app_otherLinks = action.payload;
    },
    setAppStarted(state, action) {
      state.app_started = action.payload;
    },
    setAppCompleted(state, action) {
      state.app_completed = action.payload;
    },
    setAppTCInPerson(state, action) {
      state.app_tc_inPerson = action.payload;
    },
    setAppTCMLH(state, action) {
      state.app_tc_mlh = action.payload;
    },
    setAppTCSubscribe(state, action) {
      state.app_tc_subscribe = action.payload;
    },
    setAppHackathonAttended(state, action) {
      state.app_hackathon_attended = action.payload;
    },
    setAppNumberHackathon(state, action) {
      state.app_number_hackathon = action.payload;
    },
    setAppParentEmail(state, action) {
      state.app_parent_email = action.payload;
    },
    setAppElevatorPitch(state, action) {
      state.app_elevator_pitch = action.payload;
    },
    setAppUofTHacksExp(state, action) {
      state.app_uofthacks_exp = action.payload;
    },
    setAppLearningProcess(state, action) {
      state.app_learning_process = action.payload;
    },
    setAppUofTHacksSuggestions(state, action) {
      state.app_uofthacks_suggestions = action.payload;
    },
    setQuizQ1(state, action) {
      state.quiz_q1 = action.payload;
    },
    setQuizQ2(state, action) {
      state.quiz_q2 = action.payload;
    },
    setQuizQ3(state, action) {
      state.quiz_q3 = action.payload;
    },
    setQuizQ4(state, action) {
      state.quiz_q4 = action.payload;
    },
    setQuizQ5(state, action) {
      state.quiz_q5 = action.payload;
    },
    setQuizQ6(state, action) {
      state.quiz_q6 = action.payload;
    },
    setCharacterName(state, action) {
      state.character_name = action.payload;
    },
    setReduxCharacterName(state, action) {
      state.redux_character_name = action.payload;
    },
    resetApplication(state) {
      Object.assign(state, initialState);
    },
  },
});

export { applicationSlice, rsvpSlice };
export type { ApplicationState, RSVPState };
