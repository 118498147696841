import React from "react";
import { ButtonStyle, ButtonText, StyledImage } from "./index.styles";

type ButtonProps = {
  color: string;
  image?: string;
  title: string;
  disabled?: boolean;
  onClick?: () => void;
};

const RedirectButton: React.FC<ButtonProps> = ({
  color,
  image,
  title,
  disabled = false,
  onClick,
}) => (
  <ButtonStyle color={color} onClick={onClick} disabled={disabled}>
    <StyledImage src={image} alt={title} />
    <span style={{ display: "flex", gap: "0.5rem" }}>
      <ButtonText>{title}</ButtonText>
      <img src={"/assets/home-page/redirect.svg"} alt={"Redirect"} />
    </span>
  </ButtonStyle>
);

export default RedirectButton;
