import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  RootState,
} from "../../../redux/store";
import { useMobileDetect } from "../../../hooks/useMobileDetect";
import { PassportPage, PassportHeading, PassportText } from "./index.styles";

const ProfPage2 = React.memo(
  ({
    show,
    passportNum,
    emgName,
    emgPhoneNum,
    emgRelation,
    dietCondition,
  }: {
    show: boolean;
    passportNum: string,
    emgName: string,
    emgPhoneNum: string,
    emgRelation: string,
    dietCondition: Array<string>,
  }) => {
    const {
    } = useSelector((state: RootState) => state.application);
    const dispatch = useDispatch();

    const isMobile = useMobileDetect();

    return (
      <PassportPage id="page-2" show={show}>
        <PassportHeading mobile={isMobile}>Issuing Body</PassportHeading>
        <PassportText mobile={isMobile}>UofTHacks</PassportText>

        <PassportHeading mobile={isMobile}>Passport Number </PassportHeading>
        <PassportText mobile={isMobile}>{passportNum}</PassportText>

        <PassportHeading mobile={isMobile}>Date of Issue</PassportHeading>
        {/* CHANGE TO DAY WE SEND OUT ACCEPTANCES */}
        <PassportText mobile={isMobile}>01/26/2024</PassportText>

        <PassportHeading mobile={isMobile}>Date of Expiry</PassportHeading>
        <PassportText style={{marginBottom: "min(40px, 3vw)"}} mobile={isMobile}>01/29/2024</PassportText>

        <PassportHeading mobile={isMobile}>Emergency Contact Name</PassportHeading>
        <PassportText mobile={isMobile}>{emgName}</PassportText>

        <PassportHeading mobile={isMobile}>Emergency Contact Phone Number</PassportHeading>
        <PassportText mobile={isMobile}>{emgPhoneNum}</PassportText>

        <PassportHeading mobile={isMobile}>Emergency Contact Relationship</PassportHeading>
        <PassportText mobile={isMobile}>{emgRelation}</PassportText>

        <PassportHeading mobile={isMobile}>Dietary Restrictions</PassportHeading>
        {/* NEED TO FIGURE OUT LOGIC FOR THIS */}
        <PassportText mobile={isMobile}>{dietCondition.join(", ")}</PassportText>
      </PassportPage>
    );
  }
);

export default ProfPage2;
