import styled, { keyframes, css } from "styled-components";
import Subjectivity from "../../subjectivity";
import Inter from "../../inter";
import InterSemiBold from "../../inter-semi-bold";

const rotateAnimation = keyframes`
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
`;
type HomePageContentProps = {
  isDrawerOpen?: boolean;
  mobile?: boolean | null;
  isDownloading?: boolean | null;
};

type fontProps = {
  mobile?: boolean | null;
  size?: string;
};

type PassportProps = {
  show?: boolean;
};

const StyledSubjectivity = styled(Subjectivity)<HomePageContentProps>`
  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;
  font-family: Subjectivity;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.72px;
  font-size: ${({ mobile }) => (mobile ? "36px" : "48px")};
  display: flex;
  justify-content: flex-start;
  align-items: left;
  text-align: left;
`;

const PassportHeader = styled(Subjectivity)<HomePageContentProps>`
  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;
  font-family: Subjectivity;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.72px;
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  margin: 0;
  width: 100%;

  > span {
    flex: 1;
    height: 0px;
    border-radius: 2px;
    border: 1px solid #000000;
  }

  > p {
    margin: 0 10px;
  }
`;

const PassportBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  > span {
    border-radius: 2px;
    border: 1px solid #000000;
    margin: 0 32px;
  }

  width: 100%;

  > p {
    flex: 1;
  }
`;

const PassportButton = styled.img<{ disabled?: boolean }>`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
`;

const PassportButtons = styled.div<HomePageContentProps>`
  width: ${(props) => (props.mobile ? "calc(100% - 1.25rem)" : "40%")};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${(props) => (props.mobile ? "50px 0" : "50px auto")};
`;

const PassportFooter = styled.div<HomePageContentProps>`
  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;

  text-align: center;

  display: flex;
  gap: 10px;

  width: 100%;
  > p {
    width: ${({ mobile }) => (mobile ? "100%" : "100%")};
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: clip;
    letter-spacing: 1px;
  }
`;

const StyledInterSemiBold = styled(InterSemiBold)<HomePageContentProps>`
  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;
  text-align: left;
  font-family: InterSemiBold;
  font-size: ${(props) => (props.mobile ? "16px" : "24px")};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
`;

const StyledInter = styled(Inter)<HomePageContentProps>`
  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: ${(props) => (props.mobile ? "14px" : "20px")};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 3rem;
  margin-top: 10px;
`;

const PassportPage = styled.div<PassportProps>`
  height: 480px;
  width: 100%;
  display: ${(props) => (props.show ? "block" : "none")};
`;

const Passport = styled.div<HomePageContentProps>`
  display: flex;
  width: 100%;
  min-height: 595px;
  max-width: 1152px;
  padding: 24px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 0 auto;

  box-sizing: border-box;
  ${({ mobile }) =>
    mobile ? "border-radius: 15px 0 0 15px" : "border-radius: 15px"};
  border: 1px solid var(--Black, #282828);
  background: var(--White, #f9f9f9);
  background-image: url("/assets/profile/profile-bg-moose.svg");

  /* Default Shadow */
  box-shadow: 0px 4px 0px 0px #282828;
  height: 100%;
`;

const PassportImage = styled.div<{
  imgBackground?: string;
  showingQR?: boolean;
}>`
  width: 80%;
  max-width: 180px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 15px;
  border: 1px solid var(--Black, #282828);
  background: ${(props) =>
    props.showingQR ? "#FFFFFF" : props.imgBackground ?? "transparent"};

  /* Default Shadow */
  box-shadow: 0px 4px 0px 0px #282828;
  cursor: pointer;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;

  // transition: background 0.3s ease-in-out, transform 0.5s ease-in-out;

  &:hover {
    transform: ${(props) =>
      props.showingQR ? "rotateY(0deg)" : "rotateY(180deg)"};
  }

  /* Animation for turning effect */
  ${({ showingQR }) =>
    !showingQR &&
    css`
      animation: ${rotateAnimation} 1s forwards;
    `};
`;

const PassportPhoto = styled.div<HomePageContentProps>`
  display: flex;
  width: ${({ mobile }) => (mobile ? "100%" : "auto")};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  > p {
    margin: 10px 0;
  }
`;

const PassportHeading = styled(InterSemiBold)<fontProps>`
  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;
  text-align: left;
  font-family: InterSemiBold;
  font-size: ${({ size, mobile }) =>
    size ? size : mobile ? "14px" : "min(14px, 1.9vw)"};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 0;
  margin-bottom: ${({mobile}) => mobile ? "8px" : "5px"};
`;

const PassportText = styled(Inter)<fontProps>`
  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: ${({ size, mobile }) =>
    size
      ? mobile
        ? size
        : "min(" + size + ", 3vw)"
      : mobile
      ? "16px"
      : "min(16px, 1.5vw)"};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  margin-bottom: ${({ mobile}) => mobile ? "12px" : "min(15px, 1vw)"};
`;

const QRName = styled(Inter)<HomePageContentProps>`
  color: #ffffff;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: ${({ isDrawerOpen, isMobile, isDownloading }) =>
    isDownloading ? "35px" : isMobile ? "2vw" : isDrawerOpen ? "1vw" : "0.8vw"};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  margin-bottom: min(15px, 1vw);
`;

const StyledImage = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 20px;
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
  }

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1; 
  }
`;

const MainContainer = styled.div`
  animation: ${fadeIn} 0.7s ease-in-out;
`;

export {
  StyledSubjectivity,
  StyledInter,
  StyledInterSemiBold,
  StyledImage,
  MainContainer,
  Passport,
  PassportHeader,
  PassportBody,
  PassportFooter,
  PassportButton,
  PassportButtons,
  PassportPage,
  PassportImage,
  PassportPhoto,
  PassportHeading,
  PassportText,
  QRName,
};
