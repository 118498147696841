import React from "react";
import { ButtonContainer, StyledText, StyledImage } from "./index.styles";

type DrawerButtonProps = {
  onClick?: () => void;
  title?: string;
  image?: string;
  disabled?: boolean;
  dontHover?: boolean;
};

const DrawerButton: React.FC<DrawerButtonProps> = ({
  onClick,
  title,
  image,
  disabled,
  dontHover,
  ...props
}) => {
  return (
    <ButtonContainer
      disabled={disabled}
      {...props}
      onClick={onClick}
      dontHover={disabled || dontHover}
    >
      {disabled ? (
        <StyledImage src={"/assets/drawer/locked-1.svg"} alt={title} />
      ) : (
        image && <StyledImage src={image} alt={title} />
      )}
      <StyledText disabled={disabled}>{title}</StyledText>
    </ButtonContainer>
  );
};

export { DrawerButton };
