import React from "react";
import { useSelector } from "react-redux";
import {
  RootState,
} from "../../../redux/store";
import { PassportPage } from "./index.styles";
import { StampContainer, StampStack, StampSing, StampHapAcc } from "./stamps.styles";

const ProfPage6 = React.memo(
  ({
    show,
    isMobile,
    isDrawerOpen,
    stamp_coll
  }: {
    show: boolean;
    isMobile: boolean | null;
    isDrawerOpen?: boolean | null;
    stamp_coll: string
  }) => {
    const {
    } = useSelector((state: RootState) => state.application);

    return (
      <PassportPage id="page-6" show={show}>
        <StampContainer>
        <div style={{
            display: "flex", 
            width: "100%",
            justifyContent: "right",
            alignItems: "center",
            height: "10px",
          }}>
            <StampHapAcc
              src={stamp_coll.includes("bob-ross") ? "/assets/profile/stamps/UofTHacks_happy_accidents.svg" : "/assets/profile/stamps/UofTHacks_happy_accidents_empty.svg"} 
              alt="stamp rsvp" 
              mobile={isMobile} 
              isDrawerOpen={isDrawerOpen} 
            />
          </div>
          <div style={{
            display: "flex", 
            width: "100%",
            justifyContent: "left",
            alignItems: "center",
            height: "10px",
          }}>
            <StampStack 
              src={stamp_coll.includes("cup") ? "/assets/profile/stamps/UofTHacks_stack.svg" : "/assets/profile/stamps/UofTHacks_stack_empty.svg"}  
              alt="stamp applied"
              mobile={isMobile} 
              isDrawerOpen={isDrawerOpen} 
            />
          </div>
          <div style={{
            display: "flex", 
            width: "100%",
            justifyContent: "right",
            alignItems: "center",
            height: "10px",
          }}>
            <StampSing
              src={stamp_coll.includes("sing") ? "/assets/profile/stamps/UofTHacks_sing.svg" : "/assets/profile/stamps/UofTHacks_sing_empty.svg"} 
              alt="stamp accepted" 
              mobile={isMobile} 
              isDrawerOpen={isDrawerOpen} 
            />
          </div>
        </StampContainer>
      </PassportPage>
    );
  }
);

export default ProfPage6;
