import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DashboardContainer from "../dashboard-container";
import PasswordInput from "../password-input-box";
import Input from "../input-box";
import Button from "../button";
import styled from "styled-components";
import { API_BASE_URL, API_TYPE, API_VERSION } from "../../api/apiConfig";
import { ErrorBox, StyledH2 } from "../login-form/index.styles";
import { useMobileDetect } from "../../hooks/useMobileDetect";
import LoadingCircleComponent from "../loading-component";
import { ErrorText } from "../input-box/index.styles";
import Inter from "../inter";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  height: 100%;
  align-self: center;
  align-items: center;
  align-content: center;
`;
const MainContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  justify-content: center;
`;

const StyledImage = styled.img`
  margin: 2rem 0;
`;

const StyledInter = styled(Inter)`
  color: #282828;

  text-align: center;
  leading-trim: both;

  text-edge: cap;
  /* Body 1 (16px) */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 2rem;
`;

const SuccessComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;

const ResetPassForm = () => {
  // this has two fields
  // enter new pass and re-enter new pass

  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isPasswordError, setPasswordError] = useState(false);
  const [isPasswordConfirmError, setPasswordConfirmError] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [authErrorText, setAuthErrorText] = useState("");
  const [showNextPage, setShowNextPage] = useState(false);

  const isMobile = useMobileDetect();
  const navigate = useNavigate();
  const { token } = useParams();

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  if (showNextPage) {
    return (
      <DashboardContainer>
        <StyledH2 isMobile={isMobile}>New Password Confirmed</StyledH2>
        <SuccessComponent>
          <StyledImage src="/assets/icons/checked-circle.svg" alt="success" />

          <StyledInter>
            Your password has been reset. Please login with your new password.
          </StyledInter>

          <Button
            text="BACK TO LOGIN"
            colour="blue"
            onClick={() => {
              navigate("/");
            }}
          />
        </SuccessComponent>
      </DashboardContainer>
    );
  }

  const handleResetPass = () => {
    if (!password || !passwordConfirm) {
      setAuthError(true);
      setAuthErrorText("Please fill out all fields.");
      return;
    }
    if (
      password &&
      password === passwordConfirm &&
      passwordRegex.test(password)
    ) {
      setIsLoading(true);
      fetch(`${API_BASE_URL}/${API_VERSION}/${API_TYPE.USER}/passupdate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          user_password: password,
          user_password_cmp: passwordConfirm,
        }),
      })
        .then((res) => {
          setIsLoading(false);
          if (res.ok) {
            setShowNextPage(true);
          } else {
            res.json().then((data) => {
              setAuthError(true);
              setAuthErrorText(data.message);
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setAuthError(true);
          setAuthErrorText("Something went wrong. Please try again.");
        });
    }
    if (!password || !passwordRegex.test(password)) setPasswordError(true);
    else setPasswordError(false);

    if (!passwordConfirm) setPasswordConfirmError(true);
    else setPasswordConfirmError(false);
    if (password && passwordConfirm && password !== passwordConfirm) {
      setAuthError(true);
      setAuthErrorText("Passwords do not match.");
      setPasswordError(true);
      setPasswordConfirmError(true);
    } else if (
      password &&
      passwordConfirm &&
      password === passwordConfirm &&
      !passwordRegex.test(password)
    ) {
      setAuthError(true);
      setAuthErrorText(
        "Password does not meet all requirements or has invalid special characters. Make sure to follow the requirements below."
      );
      setPasswordError(true);
    }
  };

  return (
    <DashboardContainer>
      <LoadingCircleComponent isOpen={isLoading} />
      <StyledH2 isMobile={isMobile}>Create New Password</StyledH2>

      <StyledForm
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {authError && (
          <ErrorBox>
            <img src="/assets/icons/error.svg" alt="error" />
            <ErrorText>{authErrorText}</ErrorText>
          </ErrorBox>
        )}
        <MainContainer>
          <PasswordInput
            id="password"
            title="New Password"
            type="password"
            style={{ color: "#282828" }}
            placeholder="Enter your password"
            value={password}
            isError={isPasswordError}
            onChange={(e) => {
              setPassword(e.target.value.trim());
              setPasswordError(false);
            }}
            required
          />

          <Input
            id="password-confirm"
            title="Confirm Password"
            type="password"
            style={{ color: "#282828" }}
            errorLabel="Sorry, your password does not match."
            placeholder="Re-enter your password"
            value={passwordConfirm}
            isError={isPasswordConfirmError}
            onChange={(e) => {
              setPasswordConfirm(e.target.value);
              setPasswordConfirmError(false);
            }}
            required
          />
        </MainContainer>
      </StyledForm>

      <div
        style={{
          display: "flex",
          gap: "1rem",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: isMobile ? "column" : "row",
          marginTop: "1rem",
        }}
      >
        <Button
          text="BACK TO LOGIN"
          colour="black"
          onClick={() => {
            navigate("/");
          }}
        />

        <Button text="SET PASSWORD" colour="blue" onClick={handleResetPass} />
      </div>
    </DashboardContainer>
  );
};

export default ResetPassForm;
