import React from "react";
import { ContentWrapper, StyledButton } from "./index.styles";
import { useMobileDetect } from "../../../hooks/useMobileDetect";
import { HomePageContent } from "../home";
import { FAQ } from "../faq";
import { Schedule } from "../schedule";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { RejectedHomePageContent } from "../home/rejected-home";
import { AcceptedHomePageContent } from "../home/accepted-home";
import { Profile } from "../profile";
import { RSVP } from "../rsvp";

type ContentProps = {
  isDrawerOpen: boolean;
  onToggleDrawer: () => void;
  showFAQ?: boolean;
  showSchedule?: boolean;
  showProfile?: boolean;
  showRSVP?: boolean;
};

const Content: React.FC<ContentProps> = React.memo(
  ({
    isDrawerOpen,
    onToggleDrawer,
    showFAQ,
    showProfile,
    showRSVP,
    showSchedule,
  }) => {
    const isMobile = useMobileDetect();
    const { isAccepted } = useSelector((state: RootState) => state.data);

    return (
      <>
        <StyledButton onClick={onToggleDrawer} isDrawerOpen={isDrawerOpen}>
          ☰
        </StyledButton>
        <ContentWrapper
          isDrawerOpen={isDrawerOpen}
          mobile={isMobile}
          profile={showProfile}
        >
          {showFAQ ? (
            <FAQ />
          ) : showProfile ? (
            <Profile isDrawerOpen={isDrawerOpen} />
          ) : showSchedule ? (
            <Schedule />
          ) : showRSVP ? (
            <RSVP />
          ) : isAccepted ? (
            <AcceptedHomePageContent />
          ) : (
            <RejectedHomePageContent />
            // ) : (
            //   <HomePageContent />
            // )
          )}
        </ContentWrapper>
      </>
    );
  }
);

export default Content;
