import React from "react";
import {
  DrawerWrapper,
  DrawerContent,
  StyledSubjectivity,
  DrawerButtonContainer,
  LogOutButton,
  CloseButton,
} from "./index.styles";
import { useMobileDetect } from "../../../hooks/useMobileDetect";
import { DrawerButton } from "./drawer-button";
import { useNavigate } from "react-router-dom";
import {
  RootState,
  resetApplication,
  resetData,
  resetRSVP,
  setAppPage,
  setCharacterName,
  setIsAuthenticatedRedux,
} from "../../../redux/store";
import { useDispatch } from "react-redux";
import DialogBox from "../../../components/dialog-box";
import { useSelector } from "react-redux";

type DrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

const Drawer: React.FC<DrawerProps> = ({ isOpen, onClose }) => {
  const isMobile = useMobileDetect();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = React.useState(false);

  const { isAccepted, didRSVP } = useSelector((state: RootState) => state.data);

  const { app_completed, character_name, redux_character_name } = useSelector(
    (state: RootState) => state.application
  );

  return (
    <DrawerWrapper isOpen={isOpen} mobile={isMobile}>
      <DrawerContent>
        <CloseButton onClick={onClose}>☰</CloseButton>
        <StyledSubjectivity>UofTHacks 11</StyledSubjectivity>
        <DrawerButtonContainer>
          <DrawerButton
            title={"Home"}
            image={"/assets/drawer/home-1.svg"}
            onClick={() => {
              navigate("/");
              isMobile && onClose();
            }}
          />
          {/* <DrawerButton
            title={"My Application"}
            disabled={!app_completed}
            dontHover={!app_completed}
            image={
              app_completed
                ? "/assets/drawer/app-1.svg"
                : "/assets/drawer/locked-1.svg"
            }
            onClick={() => {
              if (app_completed) {
                dispatch(setAppPage(1));
              }
              window.scrollTo({ top: 0, behavior: "smooth" });
              if (app_completed) {
                navigate("/application");
              }
              isMobile && onClose();
            }}
          />
          <DrawerButton
            title={"Personality Quiz"}
            disabled={!app_completed}
            dontHover={!app_completed}
            image={
              app_completed
                ? "/assets/drawer/quiz-1.svg"
                : "/assets/drawer/locked-1.svg"
            }
            onClick={() => {
              if (character_name === "" || character_name === undefined) {
                dispatch(setCharacterName(redux_character_name));
              }
              window.scrollTo({ top: 0, behavior: "smooth" });
              if (app_completed) {
                navigate("/quiz");
              }
              isMobile && onClose();
            }}
          /> */}

          {isAccepted && (
            <DrawerButton
              title={"My Passport"}
              image={"/assets/drawer/profile-1.svg"}
              disabled={!didRSVP}
              onClick={() => {
                navigate("/profile");
                window.scrollTo({ top: 0, behavior: "smooth" });
                isMobile && onClose();
              }}
            />
          )}
          <DrawerButton
            title={"FAQ"}
            image={"/assets/drawer/faq-1.svg"}
            onClick={() => {
              navigate("/faq");
              window.scrollTo({ top: 0, behavior: "smooth" });
              isMobile && onClose();
            }}
          />
          {isAccepted && (
            <>
              <DrawerButton
                title={"Hacker Schedule"}
                image={"/assets/drawer/schedule-1.svg"}
                disabled={!didRSVP}
                onClick={() => {
                  navigate("/schedule");
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  isMobile && onClose();
                }}
              />
              <DrawerButton
                title={"Hacker Pack"}
                image={"/assets/drawer/hacker-pack-1.svg"}
                disabled={!didRSVP}
                onClick={() => {
                  window.open("/hacker-pack.pdf", "_blank");
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  isMobile && onClose();
                }}
              />
              <DrawerButton
                title={"First Hack Guide"}
                image={"/assets/drawer/cog-1.svg"}
                disabled={!didRSVP}
                onClick={() => {
                  window.open("/hackathon-guide.pdf", "_blank");
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  isMobile && onClose();
                }}
              />
              <DrawerButton
                title={"Hacker Bingo"}
                image={"/assets/drawer/bingo-1.svg"}
                disabled={!didRSVP}
                onClick={() => {
                  window.open("/hacker-bingo.pdf", "_blank");
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  isMobile && onClose();
                }}
              />
            </>
          )}
        </DrawerButtonContainer>
        <LogOutButton>
          <DrawerButton
            title={"Log Out"}
            image={"/assets/drawer/logout.svg"}
            onClick={() => {
              setModalOpen(true);
            }}
            dontHover
          />
        </LogOutButton>
      </DrawerContent>
      <DialogBox
        isOpen={modalOpen}
        title={"Log Out Confirmation"}
        body={
          "Are you sure you want to log out of your account? Logging out will end your current session."
        }
        button1Text={"Cancel"}
        button2Text={"Log Out"}
        button1Click={() => {
          setModalOpen(false);
        }}
        button2Click={() => {
          setModalOpen(false);
          dispatch(setIsAuthenticatedRedux(false));
          dispatch(resetApplication());
          dispatch(resetData());
          dispatch(resetRSVP());
          navigate("/");
        }}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </DrawerWrapper>
  );
};

export default Drawer;
