import Inter from "../inter";
import styled from "styled-components";
import InterSemiBold from "../inter-semi-bold";

type ErrorProps = {
  isError?: boolean | null;
};

type OptionProps = {
  type?: string;
  disabled?: boolean;
};

const Label = styled.label`
  display: block;
`;

const OptionLabel = styled.label`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledInterLabel = styled(InterSemiBold)`
  font-size: 16px;
  text-align: left;

  color: var(--Default-Stroke, #282828);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const StyledInterOption = styled(Inter)<ErrorProps>`
  font-size: 14px;
  text-align: left;
  color: #555555;
  border-radius: 5px;
  margin: 10px 0;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Option = styled.input<ErrorProps>`
  display: none;
`;

const ErrorLabel = styled(Inter)<ErrorProps>`
  display: ${(props) => (props.isError ? "flex" : "none")};
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  text-align: left;
  color: #cb3101;
`;

const OptionBox = styled.span<OptionProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  background-color: #f9f9f9;
  border: 1px solid #282828;
  border-radius: ${(props) => (props.type === "radio" ? "50%" : "5px")};
  cursor: pointer;
  margin-right: 10px;
  & input:checked ~ span {
    ${(props) =>
      props.type === "radio"
        ? `background-color: #2850A0;`
        : `border: solid #2850A0;
    border-width: 0 3px 3px 0;`}
  }
  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
    background-color: lightgray;
    cursor: not-allowed;
  `}
`;

const OptionClick = styled.span<OptionProps>`
  display: inline-block;
  height: 15px;
  ${(props) =>
    props.type === "radio"
      ? `width: 15px;
  border-radius: 50%;`
      : `width: 5px; margin-bottom: 3px;
  cursor: pointer;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);`}
`;

const ErrorText = styled.div`
  height: 100%;
  display: inline;
  margin: auto 10px;
`;

const RequiredStarStyle = styled.span`
  color: #cb3101;
  margin-left: 0.25rem;
`;

export {
  ErrorLabel,
  ErrorText,
  Label,
  StyledInterLabel,
  StyledInterOption,
  OptionBox,
  OptionClick,
  Option,
  OptionLabel,
  RequiredStarStyle,
};
