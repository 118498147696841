import React from "react";
import styled, { keyframes } from "styled-components";
import Modal from "../modal";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 10000;
`;

const LoadingCircle = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 4px solid #282828;
  border-top-color: darkgrey;
  animation: ${spin} 2s linear infinite;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type LoadingCircleProps = {
  isOpen: boolean;
};

const LoadingCircleComponent: React.FC<LoadingCircleProps> = ({ isOpen }) => {
  return (
    <Modal isOpen={isOpen}>
      <LoadingContainer>
        <LoadingCircle>
          <img src="/favicon-32x32.png" alt="logo" width={50} height={50} />
        </LoadingCircle>
      </LoadingContainer>
    </Modal>
  );
};

export default LoadingCircleComponent;
