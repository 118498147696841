import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMobileDetect } from "../../../hooks/useMobileDetect";
import { RootState } from "../../../redux/store";
import {
  PassportPage,
  PassportImage,
  PassportPhoto,
  PassportText,
  PassportHeading,
} from "./index.styles";
import QRCode from "react-qr-code";
import { charDetails } from "../../quiz/characters";

const ProfPage1 = React.memo(
  ({
    show,
    age,
    name,
    pronoun,
    school,
    program,
    qrCode,
  }: {
    show: boolean;
    age: string;
    name: string;
    pronoun: string;
    school: string;
    program: string;
    qrCode: string;
  }) => {
    const {} = useSelector((state: RootState) => state.application);
    const isMobile = useMobileDetect();
    const dispatch = useDispatch();
    const [showingQRCode, setShowingQRCode] = useState(true);

    useEffect(() => {
      setTimeout(() => {
        setShowingQRCode(false);
      }, 1);
    }, []);

    const character = useSelector(
      (state: RootState) => state.application.character_name
    );

    const { imageSrc, imgBackground } = charDetails[
      character.toLowerCase() as keyof typeof charDetails
    ]
      ? charDetails[character.toLowerCase() as keyof typeof charDetails]
      : {
          imageSrc: "/assets/characters/cat.svg",
          imgBackground: "#F9F9F9",
        };

    return (
      <PassportPage id="page-1" show={show}>
        <div
          style={{
            display: "flex",
            marginBottom: "20px",
            flexWrap: "wrap",
            alignContent: "center",
            width: "100%",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            // DO SOME LOGIC HERE TO CHECK IF QR CODE EXISTS, IF NOT, DONT CHANGE
            setShowingQRCode(!showingQRCode);
          }}
        >
          <PassportPhoto>
            <PassportImage
              showingQR={showingQRCode}
              imgBackground={imgBackground}
            >
              <img
                src={imageSrc}
                alt="passport photo"
                style={{
                  width: "100%",
                  display: showingQRCode ? "none" : "block",
                }}
              />
              <QRCode
                value={qrCode}
                style={{
                  width: "100%",
                  height: "80%",
                  backgroundColor: "",
                  display: showingQRCode ? "block" : "none",
                }}
              />
            </PassportImage>
            <PassportText
              size="12px"
              style={{
                userSelect: "none",
              }}
            >
              {isMobile ? "Tap" : "Click"} for QR Code
            </PassportText>
          </PassportPhoto>
        </div>
        <PassportHeading mobile={isMobile}>Name</PassportHeading>
        <PassportText size="34px" mobile={isMobile}>
          {name}
        </PassportText>
        <div style={{ display: "flex", flexDirection: isMobile ? "column": "row", justifyContent: "space-between", gap: isMobile ? "0" : "10px" }}>
          <div style={{ width: isMobile? "100%" : "50%" }}>
            <PassportHeading mobile={isMobile}>Pronouns</PassportHeading>
            <PassportText mobile={isMobile}>{pronoun}</PassportText>
          </div>
          <div style={{ width: isMobile? "100%" : "50%" }}>
            <PassportHeading mobile={isMobile}>Age</PassportHeading>
            <PassportText mobile={isMobile}>{age}</PassportText>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: isMobile ? "column": "row", justifyContent: "space-between", gap: isMobile ? "0" : "10px" }}>
          <div style={{ width: isMobile? "100%" : "50%" }}>
            <PassportHeading mobile={isMobile}>School</PassportHeading>
            <PassportText mobile={isMobile}>{school}</PassportText>
          </div>
          <div style={{ width: isMobile? "100%" : "50%" }}>
            <PassportHeading mobile={isMobile}>Program</PassportHeading>
            <PassportText mobile={isMobile}>{program}</PassportText>
          </div>
        </div>
        {/* <PassportHeading mobile={isMobile}>School</PassportHeading>
        <PassportText mobile={isMobile}>{school}</PassportText>
        <PassportHeading mobile={isMobile}>Program</PassportHeading>
        <PassportText mobile={isMobile}>{program}</PassportText> */}
      </PassportPage>
    );
  }
);

export default ProfPage1;
