import Inter from "../inter";
import styled from "styled-components";
// import { useMobileDetect } from "../../hooks/useMobileDetect";

const StyledInterButton = styled(Inter)`
  text-align: center;
  leading-trim: both;
  font-size: 14px;
  text-edge: cap;
  /* Button */
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.28px;
  margin: 10px;
`;

const StyledInterRSVPButtonText = styled(Inter)`
  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.025rem;
`;

type ButtonProps = {
  colour?: string;
  textColour?: string;
  notTransparent?: boolean;
  isMobile?: boolean | null;
};

const StyledButton = styled.button<ButtonProps>`
  background-color: ${(props) =>
    props.colour === "blue" ? "#2850A0" : props.colour};
  color: ${(props) =>
    props.textColour ? props.textColour : "#f9f9f9"};
  text-decoration: none;
  min-width: ${(props) => (props.isMobile ? "90px" : "11rem")};
  padding: 0 20px;
  height: 50px;
  border: 1px solid #282828;
  border-radius: 10px;
  transition: ease-in 0.2s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover {
    border: ${(props) =>
      props.notTransparent
        ? "3px solid #282828"
        : props.colour === "blue"
        ? "3px solid #2850A0"
        : props.colour 
          ? " 3px solid " + props.colour
          : "3px solid #282828"};
    text-decoration: underline;
    background-color: ${(props) =>
      props.notTransparent
        ? props.colour === "blue"
          ? "#2850A0"
          : props.colour
        : " transparent"};
    color: ${(props) =>
      props.notTransparent
        ? "#F9F9F9"
        : props.colour === "blue"
        ? "#2850A0"
        : props.colour};
    cursor: pointer;
  }

  ${(props) =>
    props.disabled &&
    `
    background-color: grey;
    &:hover {
      border: 1px solid #282828;
      text-decoration: none;
      background-color: grey;
      color: #F9F9F9;
      cursor: not-allowed;
    }
    `}
`;

const RSVPButtonContainer = styled(StyledButton)`
  &:hover {
    background-color: #f9f9f9;
    color: #f9f9f9;
    text-decoration: none;
    min-width: ${(props) => (props.isMobile ? "90px" : "11rem")};
    padding: 0 20px;
    height: 50px;
    border: 1px solid #282828;
    border-radius: 10px;
    transition: ease-in 0.2s;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export {
  StyledInterButton,
  StyledButton,
  StyledInterRSVPButtonText,
  RSVPButtonContainer,
};
