import React from "react";
import {
    StyledInter,
    StyledLegendKey,
    ColourBox,
  } from "./index.styles";

const LegendKey = React.memo(
  ({ colour, name }: { colour: string; name: string }) => {
    return (
      <StyledLegendKey>
        <ColourBox colour={colour}/>
        <StyledInter>{name}</StyledInter>
      </StyledLegendKey>
    );
  }
);

export default LegendKey;
