import React, { useState } from "react";
import EmailSentComponent from "../../components/reset-password/email-sent";
import ResetPassComponent from "../../components/reset-password/reset-pass";

const ResetPasswordForm = () => {
  const [emailSent, setEmailSent] = useState(false);
  if (!emailSent) {
    return (
      <ResetPassComponent
        onClick={() => {
          setEmailSent(true);
        }}
      />
    );
  }

  return <EmailSentComponent />;
};
export default ResetPasswordForm;
