import styled, { keyframes } from "styled-components";
import Subjectivity from "../../subjectivity";
import Inter from "../../inter";
import InterSemiBold from "../../inter-semi-bold";

type StampProps = {
  isDrawerOpen?: boolean | null;
  mobile?: boolean | null;
};

const StampContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const StampApplied = styled.img<StampProps>`
  width: 60%;
  min-width: ${({ mobile, isDrawerOpen }) =>
    mobile ? "220px" : isDrawerOpen ? "128px" : "220px"};
  max-width: 300px;
  margin-left: min(20px, 2%);
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
`;

const StampAccepted = styled.img<StampProps>`
  width: 45%;
  min-width: ${({ mobile, isDrawerOpen }) =>
    mobile ? "180px" : isDrawerOpen ? "128px" : "175px"};
  max-width: 250px;
  margin-top: min(70px, 10%);
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
`;
const StampRSVP = styled.img<StampProps>`
  width: 65%;
  min-width: ${({ mobile, isDrawerOpen }) =>
    mobile ? "230px" : isDrawerOpen ? "128px" : "230px"};
  max-width: 350px;
  margin-top: 20px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
`;

const StampSwag = styled.img<StampProps>`
    width: 55%;
    min-width: ${({ mobile, isDrawerOpen }) => (mobile ? "170px" : isDrawerOpen ? "128px" : "175px")};
    max-width: 250px;
    margin-left: min(20px, 2%);
`

const StampFuel = styled.img<StampProps>`
    width: 55%;
    min-width: ${({ mobile, isDrawerOpen }) => (mobile ? "170px" : isDrawerOpen ? "128px" : "175px")};
    max-width: 250px;
`
const StampCheckedIn = styled.img<StampProps>`
    width: 65%;
    min-width: ${({ mobile, isDrawerOpen }) => (mobile ? "200px" : isDrawerOpen ? "128px" : "200px")};
    max-width: 265px;
    margin-left: min(50px, 5%);
`

const StampSponsor2 = styled.img<StampProps>`
    width: 50%;
    min-width: ${({ mobile, isDrawerOpen }) => (mobile ? "150px" : isDrawerOpen ? "110px" : "160px")};
    max-width: 250px;
    margin-left: min(20px, 2%); 
`

const StampSponsor3 = styled.img<StampProps>`
    width: 55%;
    margin-bottom: min(100px, 15%);
    min-width: ${({ mobile, isDrawerOpen }) => (mobile ? "180px" : isDrawerOpen ? "128px" : "190px")};
    max-width: 280px;
`

const StampSponsor1 = styled.img<StampProps>`
    width: 60%;
    min-width: ${({ mobile, isDrawerOpen }) => (mobile ? "190px" : isDrawerOpen ? "128px" : "190px")};
    max-width: 265px;
    margin-left: 15%;
    margin-bottom: 20px;
`

const StampStack = styled.img<StampProps>`
    width: 55%;
    min-width: ${({ mobile, isDrawerOpen }) => (mobile ? "220px" : isDrawerOpen ? "128px" : "170px")};
    max-width: 270px;
    margin-bottom: min(60px, 15%);
`
const StampSing = styled.img<StampProps>`
    width: ${({ mobile}) => (mobile ? "45%" : "50%")};
    min-width: ${({ mobile, isDrawerOpen }) => (mobile ? "210px" : isDrawerOpen ? "110px" : "180px")};
    max-width: 250px;
    margin-bottom: 200px;
    margin-right: 10%;
`

const StampHapAcc = styled.img<StampProps>`
    width: ${({ mobile}) => (mobile ? "50%" : "45%")};
    min-width: ${({ mobile, isDrawerOpen }) => (mobile ? "180px" : isDrawerOpen ? "110px" : "150px")};
    max-width: 240px;
    margin-top: min(180px, 60%);
    margin-right: min(20px, 2%);
`


export {
  StampContainer,
  StampApplied,
  StampAccepted,
  StampRSVP,
  StampSwag,
  StampFuel,
  StampCheckedIn,
  StampSponsor1,
  StampSponsor2,
  StampSponsor3,
  StampStack,
  StampSing,
  StampHapAcc
};
