import styled, { keyframes } from "styled-components";
import Inter from "../inter";
import InterSemiBold from "../inter-semi-bold";

const DialogBoxWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0 1.25rem 1.25rem 1.25rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 0.9375rem;
  border: 3px solid var(--Black, #282828);
  background: var(--White, #f9f9f9);
  box-shadow: 0px 4px 0px 0px #282828;
  color: var(--Black, #282828);
`;

const DialogBoxHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  align-content: center;
  align-self: center;
`;

const DialogBoxTitle = styled(InterSemiBold)`
  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;
  font-family: InterSemiBold;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.03rem;
  display: flex;
  text-align: left;
  margin-bottom: 0.2rem;
`;

const DialogBoxBody = styled(Inter)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  margin-bottom: 4rem;

  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 1.3rem */
`;

const CloseButton = styled.svg`
  background: transparent;
  border: none;
  font-size: 2rem;
  color: var(--Black, #282828);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 1rem;
`;

export {
  DialogBoxWrapper,
  DialogBoxHeader,
  DialogBoxTitle,
  DialogBoxBody,
  CloseButton,
  ButtonContainer,
};
