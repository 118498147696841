import { useMobileDetect } from "../../hooks/useMobileDetect";
import React, { useRef, useEffect } from "react";
import styled from "styled-components";

type ModalProps = {
  isOpen?: boolean;
  closeModal?: () => void;
  children: React.ReactNode;
};

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Translucent background */
  z-index: 100;
`;

const ContentWrapper = styled.div<{ isMobile?: boolean | null }>`
  margin: 0 2rem;
  border-radius: 15px;
  width: 100%;
  max-width: ${({ isMobile }) => (isMobile ? "80%" : "600px")};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = ({ isOpen, closeModal, children }: ModalProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const isMobile = useMobileDetect();
  const handleOutsideClick = (e: MouseEvent) => {
    if (contentRef.current && !contentRef.current.contains(e.target as Node)) {
      closeModal && closeModal();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <ModalWrapper>
      <ContentWrapper ref={contentRef} isMobile={isMobile}>
        {children}
      </ContentWrapper>
    </ModalWrapper>
  );
};

export default Modal;
