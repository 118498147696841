import styled from "styled-components";
import Subjectivity from "../subjectivity";
import Inter from "../inter";

type LoginProps = {
  isMobile?: boolean | null;
};

const DashboardContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1.25rem;
`;
const StyledH2 = styled(Subjectivity)<LoginProps>`
  text-shadow: none;
  font-size: ${(props) => (props.isMobile ? "24px" : "48px")};
  letter-spacing: ${(props) => (props.isMobile ? "-1px" : "-2.4px")};
  margin-top: ${(props) => (props.isMobile ? "0" : "1.9rem")};
  margin-bottom: 20px;
  color: var(--Default-Stroke, #282828);
`;

const StyledInter = styled(Inter)<LoginProps>`
  margin: 0;
  margin: 3rem 0;
  font-size: ${(props) => (props.isMobile ? "10px" : "16px")};
`;

const StyledSVG = styled.svg`
  margin: 2rem 0;
`;

export { DashboardContents, StyledH2, StyledInter, StyledSVG };
