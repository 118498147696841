import React, { useEffect, useState } from "react";
import {
  StyledSubjectivity,
  StyledInter,
  ButtonContainer,
  NoneButton,
  DashboardHeaderContainer,
  Image,
  BouncingImage,
  StyledLink,
} from "../index.styles";
import { useMobileDetect } from "../../../../hooks/useMobileDetect";
import { HomeButton } from "../home-button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useDispatch } from "react-redux";

type HomeProps = {
  isDrawerOpen?: boolean;
};

const RejectedHomePageContent: React.FC<HomeProps> = React.memo(() => {
  const [catImg, setCatImg] = useState("none");

  const { userFName } = useSelector((state: RootState) => state.data);
  const { app_completed } = useSelector(
    (state: RootState) => state.application
  );

  // get first name from user name if exists and store in name
  let name = userFName
    ? userFName.charAt(0).toUpperCase() + userFName.slice(1).toLowerCase()
    : "";

  const dashboardHeader = name ? "Welcome, " + name + "!" : "Welcome!";
  const rejectedText = (
    <>
      Thank you so much for your interest in UofTHacks 11! See you all next year
      :) <br /> Meanwhile, you can follow us on Instagram (
      <StyledLink href="https://www.instagram.com/uofthacks" target="_blank">
        @uofthacks
      </StyledLink>
      ) to stay up to date with our latest announcements!
    </>
  );

  const isMobile = useMobileDetect();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showEasterEgg = () => {
    if (catImg === "none") {
      setCatImg("block");
    } else {
      setCatImg("none");
    }
  };

  useEffect(() => {
    const keyDownHandler = (event: {
      key: string;
      preventDefault: () => void;
    }) => {
      if (event.key === "Escape") {
        showEasterEgg();
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [catImg]);

  return (
    <>
      <ButtonContainer mobile={isMobile}>
        <DashboardHeaderContainer mobile={isMobile}>
          <StyledSubjectivity mobile={isMobile}>
            {dashboardHeader}
          </StyledSubjectivity>
          <StyledInter>{rejectedText}</StyledInter>
          {catImg === "none" && (
            <Image src="/assets/stars/star.svg" alt="" mobile={isMobile} />
          )}
          <BouncingImage
            src="/assets/characters/cat.svg"
            alt=""
            display={catImg}
            mobile={isMobile}
          />
        </DashboardHeaderContainer>
      </ButtonContainer>
      <ButtonContainer mobile={isMobile}>
        <HomeButton
          color="#E3A1E3"
          title="FAQ / Contact Us"
          image="/assets/home-page/faq.svg"
          onClick={() => navigate("/faq")}
        />
        <NoneButton />
      </ButtonContainer>
    </>
  );
});

export { RejectedHomePageContent };
