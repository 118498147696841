import styled from "styled-components";

type ContentWrapperProps = {
  isDrawerOpen?: boolean;
  mobile?: boolean | null;
  profile?: boolean | null;
};

const ContentWrapper = styled.div<ContentWrapperProps>`
  margin-left: ${({ isDrawerOpen }) => (isDrawerOpen ? "250px" : "0")};
  transition: margin-left 0.3s ease-in-out;
  height: 100%;
  padding: ${({ mobile, profile }) => 
    (mobile 
      ? profile 
        ? "3.75rem 0 3.75rem 1.25rem" 
        : "3.75rem 1.25rem" 
      : "3.75rem")};
  ${({ mobile, isDrawerOpen }) =>
    isDrawerOpen &&
    mobile &&
    `
    overflow: hidden;
    overflow: hidden;
    opacity: 0; 
    transition: opacity 0.3s ease-in-out;
    margin-left: 0;
    `}
`;

const StyledButton = styled.div<ContentWrapperProps>`
  position: absolute;
  margin-top: 1.25rem;
  margin-left: 1.25rem;
  display: ${({ isDrawerOpen }) => (isDrawerOpen ? "none" : "flex")};
  cursor: pointer;
  font-size: 30px;
  color: var(--Black, var(--Default-Stroke, #282828));
  transition: color 0.3s ease;
  &:hover {
    color: grey;
  }
`;

export { ContentWrapper, StyledButton };
