import React, { useEffect, useState } from "react";
import {
  Label,
  StyledInterLabel,
  StyledInterInput,
  InputBox,
  ErrorLabel,
  NonErrorLabel,
  ErrorText,
  EyeImage,
} from "./index.styles";
import Inter from "../inter";

interface Props {
  type?: string;
  id: string;
  title?: string;
  style?: any;
  isError?: boolean;
  errorLabel?: string;
  placeholder?: string;
  required?: boolean;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const toggleImg = (id: string) => {
  let password = document.getElementById(id) as HTMLInputElement;
  let passwordToggle = document.getElementById(
    id + "-error"
  ) as HTMLImageElement;
  if (password) {
    if (password.type === "password") {
      password.type = "text";
      passwordToggle.src = "/assets/icons/eye.svg";
      passwordToggle.style.top = "14px";
    } else {
      password.type = "password";
      passwordToggle.src = "/assets/icons/eye-visible.svg";
      passwordToggle.style.top = "12px";
    }
  }
};

const ValidationText: React.FC<{
  isError: boolean | undefined;
  password: string | undefined;
}> = ({ isError, password }) => {
  const validCharRegex = /^[a-zA-Z\d@$!%#?&]*$/;

  const isSatisfied = {
    length: password ? password.length >= 8 : false,
    lowercase: password ? /[a-z]/.test(password) : false,
    uppercase: password ? /[A-Z]/.test(password) : false,
    number: password ? /\d/.test(password) : false,
    specialChar: password ? /[@$!%#?&]/.test(password) : false,
    invalidChar: password ? validCharRegex.test(password) : false,
  };

  const allSatisfied =
    isSatisfied.lowercase &&
    isSatisfied.uppercase &&
    isSatisfied.number &&
    isSatisfied.specialChar &&
    isSatisfied.invalidChar;

  const errorField = {
    length: isError && !isSatisfied.length,
    lowercase: isError && !isSatisfied.lowercase,
    uppercase: isError && !isSatisfied.uppercase,
    number: isError && !isSatisfied.number,
    specialChar: isError && !isSatisfied.specialChar,
    invalidChar: isError && !isSatisfied.invalidChar,
    allSatisfied: isError && !allSatisfied,
  };

  const greenBulletColor = "#047c8c";
  const greyBulletColor = "#b6b6b6";
  const redBulletColor = "#cb3101";
  const satisfiedTextColor = "#282828";
  const unsatisfiedTextColor = "#555555";

  const textStyles = {
    color: isError
      ? redBulletColor
      : isSatisfied
      ? satisfiedTextColor
      : unsatisfiedTextColor,
    fontSize: "0.85rem",
    lineHeight: "0.85rem",
  };

  const bulletStyles = {
    color: isError
      ? redBulletColor
      : isSatisfied
      ? satisfiedTextColor
      : unsatisfiedTextColor,
    listStyleType: "disc",
  };

  return (
    <ErrorText>
      <div>
        <Inter
          style={{
            ...textStyles,
            color:
              errorField.allSatisfied || errorField.length
                ? redBulletColor
                : satisfiedTextColor,
          }}
        >
          Your password must:
        </Inter>
        <ul
          style={{
            ...bulletStyles,
            color: isError ? redBulletColor : greenBulletColor,
          }}
        >
          <li
            style={{
              ...bulletStyles,
              color: errorField.length
                ? redBulletColor
                : isSatisfied.length
                ? greenBulletColor
                : greyBulletColor,
            }}
          >
            <Inter
              style={{
                ...textStyles,
                color: errorField.length
                  ? redBulletColor
                  : isSatisfied.length
                  ? satisfiedTextColor
                  : greyBulletColor,
              }}
            >
              Be at least 8 characters in length
            </Inter>
          </li>
          <li
            style={{
              ...bulletStyles,
              color: errorField.allSatisfied
                ? redBulletColor
                : allSatisfied
                ? greenBulletColor
                : greyBulletColor,
            }}
          >
            <Inter
              style={{
                ...textStyles,
                color: errorField.allSatisfied
                  ? redBulletColor
                  : allSatisfied
                  ? satisfiedTextColor
                  : greyBulletColor,
              }}
            >
              Contain:
            </Inter>
            <ul
              style={{
                ...bulletStyles,
                color: isError ? redBulletColor : greenBulletColor,
              }}
            >
              <li
                style={{
                  ...bulletStyles,
                  color: errorField.lowercase
                    ? redBulletColor
                    : isSatisfied.lowercase
                    ? greenBulletColor
                    : greyBulletColor,
                }}
              >
                <Inter
                  style={{
                    ...textStyles,
                    color: errorField.lowercase
                      ? redBulletColor
                      : isSatisfied.lowercase
                      ? satisfiedTextColor
                      : greyBulletColor,
                  }}
                >
                  A lower case letter (a-z)
                </Inter>
              </li>
              <li
                style={{
                  ...bulletStyles,
                  color: errorField.uppercase
                    ? redBulletColor
                    : isSatisfied.uppercase
                    ? greenBulletColor
                    : greyBulletColor,
                }}
              >
                <Inter
                  style={{
                    ...textStyles,
                    color: errorField.uppercase
                      ? redBulletColor
                      : isSatisfied.uppercase
                      ? satisfiedTextColor
                      : greyBulletColor,
                  }}
                >
                  An upper case letter (A-Z)
                </Inter>
              </li>
              <li
                style={{
                  ...bulletStyles,
                  color: errorField.number
                    ? redBulletColor
                    : isSatisfied.number
                    ? greenBulletColor
                    : greyBulletColor,
                }}
              >
                <Inter
                  style={{
                    ...textStyles,
                    color: errorField.number
                      ? redBulletColor
                      : isSatisfied.number
                      ? satisfiedTextColor
                      : greyBulletColor,
                  }}
                >
                  A number (i.e. 0-9)
                </Inter>
              </li>
              <li
                style={{
                  ...bulletStyles,
                  color: errorField.specialChar
                    ? redBulletColor
                    : isSatisfied.specialChar
                    ? greenBulletColor
                    : greyBulletColor,
                }}
              >
                <Inter
                  style={{
                    ...textStyles,
                    color: errorField.specialChar
                      ? redBulletColor
                      : isSatisfied.specialChar
                      ? satisfiedTextColor
                      : greyBulletColor,
                  }}
                >
                  A special character (@$!%#?&)
                </Inter>
              </li>
              <li
                style={{
                  ...bulletStyles,
                  color: errorField.invalidChar
                    ? redBulletColor
                    : isSatisfied.invalidChar
                    ? greenBulletColor
                    : greyBulletColor,
                }}
              >
                <Inter
                  style={{
                    ...textStyles,
                    color: errorField.invalidChar
                      ? redBulletColor
                      : isSatisfied.invalidChar
                      ? satisfiedTextColor
                      : greyBulletColor,
                  }}
                >
                  No Invalid Characters (eg. of invalid characters: &lt; &gt;
                  &quot; ' . , - _ * () + - =, etc.)
                </Inter>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </ErrorText>
  );
};

const PasswordInput = ({
  id,
  type,
  title,
  style,
  isError,
  placeholder,
  required,
  value,
  onChange,
}: Props) => {
  const [isFocused, setIsFocused] = useState(true);

  useEffect(() => {
    setIsFocused(false);
  }, []);

  return (
    <div>
      <Label htmlFor={id}>
        <StyledInterLabel>{title}</StyledInterLabel>
      </Label>
      <StyledInterInput isError={isError}>
        <InputBox
          type={type}
          id={id}
          style={style}
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={onChange}
          onFocus={() => setIsFocused(true)}
          // onBlur={() => setIsFocused(false)}
        />
        <EyeImage
          src="/assets/icons/eye-visible.svg"
          alt="eye"
          type={type}
          id={id + "-error"}
          onClick={() => {
            toggleImg(id);
          }}
        />
      </StyledInterInput>
      <ErrorLabel isError={isError}>
        <ValidationText isError={isError} password={value} />
      </ErrorLabel>
      <NonErrorLabel show={!isError && isFocused}>
        <ValidationText isError={isError} password={value} />
      </NonErrorLabel>
    </div>
  );
};

export default PasswordInput;
