import React, { useEffect } from "react";
import {
  StyledSubjectivity,
  StyledInter,
  StyledInterSemiBold,
  StyledImage,
  MainContainer,
} from "./index.styles";
import { useMobileDetect } from "../../../hooks/useMobileDetect";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import faqs from "./faqs";

type HomeProps = {
  isDrawerOpen?: boolean;
};

const FAQ: React.FC<HomeProps> = ({ isDrawerOpen }) => {
  const isMobile = useMobileDetect();
  const navigate = useNavigate();
  const faqList = faqs.map((faq) => (
    <>
      <StyledInterSemiBold>{faq.question}</StyledInterSemiBold>
      <StyledInter>{faq.answer}</StyledInter>
    </>
  ));

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <MainContainer>
      <div style={{ width: isMobile ? "100%" : "90%" }}>
        <StyledSubjectivity mobile={isMobile}>
          <StyledImage
            src="/assets/home-page/back-button.svg"
            onClick={() => navigate("/")}
          />
          Frequently Asked Questions
        </StyledSubjectivity>
        {faqList}
      </div>
    </MainContainer>
  );
};

export { FAQ };
