import styled from "styled-components";
import InterSemiBold from "../../components/inter-semi-bold";
import Inter from "../../components/inter";

type LoginProps = {
  isMobile?: boolean | null;
};

const DashboardContents = styled.div<LoginProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledH2 = styled(InterSemiBold)<LoginProps>`
  color: var(--Default-Stroke, #282828);
  leading-trim: both;
  text-edge: cap;
  /* H2 */
  font-family: Subjectivity;
  font-size: ${(props) => (props.isMobile ? "2rem" : "3rem")};
  letter-spacing: ${(props) => (props.isMobile ? "-0.1rem" : "-0.15rem")};
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.15rem;
  margin: 0.75rem 0 3.75rem 0;
`;

const StyledInter = styled(Inter)`
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  width: 100%;
  margin-bottom: 1rem;
`;

const InputContainer = styled.div`
  align-items: center;
  display: grid;
  width: 100%;
  margin-bottom: 3.75rem;
`;

const ResetForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 5rem);
  max-width: 40rem;
`;

const FlexButtonContainer = styled.div<LoginProps>`
  display: flex;
  justify-content: center;
  gap: ${(props) => (props.isMobile ? "2rem" : "3rem")};
  width: ${(props) => (props.isMobile ? "100%" : "100%")};
`;

const ErrorBox = styled.div`
  display: flex;
  padding: 1rem;
  border-radius: 5px;
  color: #cb3101;
  background-color: #fdecea;
`;

const ErrorText = styled.span`
  height: 100%;
  display: inline;
  margin: auto 10px;
`;

export {
  DashboardContents,
  StyledH2,
  StyledInter,
  ResetForm,
  FlexButtonContainer,
  InputContainer,
  ErrorBox,
  ErrorText,
};
