const dietaryOptions = [
  "Vegetarian",
  "Vegan",
  "Kosher",
  "Halal",
  "Gluten-free",
  "Lactose-free",
  "None",
  "Other",
];

const relationshipOptions = ["Parent", "Sibling", "Friend (18+)", "Grandparent", "Partner", "Guardian"];

const merchOptions = ["XS", "S", "M", "L", "XL", "XXL"];

const demographicOptions = [
  "Asian Indian",
  "Black or African",
  "Chinese",
  "Filipino",
  "Guamanian or Chamorro",
  "Hispanic / Latino / Spanish Origin",
  "Japanese",
  "Korean",
  "Middle Eastern",
  "Native American or Alaskan Native",
  "Native Hawaiian",
  "Samoan",
  "Vietnamese",
  "White",
  "Other Asian (Thai, Cambodian, etc)",
  "Other Pacific Islander",
  "Other",
  "Prefer Not to Answer",
];

export {
  dietaryOptions,
  demographicOptions,
  relationshipOptions,
  merchOptions,
};
