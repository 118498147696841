import React, { useEffect, useState } from "react";
import {
  StyledSubjectivity,
  StyledInter,
  StyledInterSemiBold,
  StyledImage,
  MainContainer,
  DaySelector,
  Event,
  EventInfo,
  EventHeader,
  EventHeaderInfo
} from "./index.styles";
import { useMobileDetect } from "../../../hooks/useMobileDetect";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { 
  legend,
  fridaySchedule, 
  saturdaySchedule,
  sundaySchedule 
} from "./schedule";
import LegendKey from "./LegendKey";

type HomeProps = {
  isDrawerOpen?: boolean;
};

const Schedule: React.FC<HomeProps> = ({ isDrawerOpen }) => {
  // Day 1: Friday, Day 2: Saturday, Day 3: Sunday
  const [day, setDay] = useState(1);
  const isMobile = useMobileDetect();
  const navigate = useNavigate();

  const legendKeys = ["sponsor", "mini", "food"]

  const fridayEvents = fridaySchedule.map((event) => (
    <Event colour={legend[event.type as keyof typeof legend]["colour"]} mobile={isMobile}>
      <EventInfo width="250px" align="left" bold={isMobile} mobile={isMobile}>{event.name}</EventInfo>
      <EventInfo width="100px" align={isMobile ? "left" :"center"} mobile={isMobile}>{event.time}</EventInfo>
      <EventInfo width="200px" align="right" mobile={isMobile}>{event.location}</EventInfo>
    </Event>
  ));
  const saturdayEvents = saturdaySchedule.map((event) => (
    <Event colour={legend[event.type as keyof typeof legend]["colour"]} mobile={isMobile}>
      <EventInfo width="250px" align="left" bold={isMobile} mobile={isMobile}>{event.name}</EventInfo>
      <EventInfo width="100px" align={isMobile ? "left" :"center"} mobile={isMobile}>{event.time}</EventInfo>
      <EventInfo width="200px" align="right" mobile={isMobile}>{event.location}</EventInfo>
    </Event>
  ));
  const sundayEvents = sundaySchedule.map((event) => (
    <Event colour={legend[event.type as keyof typeof legend]["colour"]} mobile={isMobile}>
      <EventInfo width="250px" align="left" bold={isMobile} mobile={isMobile}>{event.name}</EventInfo>
      <EventInfo width="100px" align={isMobile ? "left" :"center"} mobile={isMobile}>{event.time}</EventInfo>
      <EventInfo width="200px" align="right" mobile={isMobile}>{event.location}</EventInfo>
    </Event>
  ));

  const eventLegend = legendKeys.map((eventType) =>(
    <LegendKey colour={legend[eventType as keyof typeof legend]["colour"]} name={legend[eventType as keyof typeof legend]["event"]}/>
  ));

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <MainContainer>
      <div style={{ width: isMobile ? "100%" : "90%" }}>
        <StyledSubjectivity mobile={isMobile}>
          <StyledImage
            src="/assets/home-page/back-button.svg"
            onClick={() => navigate("/")}
          />
          Schedule
        </StyledSubjectivity>
        <div style={{display: "flex", gap: isMobile ? "" : "32px", justifyContent: isMobile ? "space-between" : "", marginBottom: "20px"}}>
          <DaySelector 
            selected={day == 1} 
            mobile={isMobile} 
            onClick={() => (setDay(1))}
          >
            {isMobile ? "Friday Jan 26" : "Friday, January 26" }
          </DaySelector>
          <DaySelector 
            selected={day == 2} 
            mobile={isMobile} 
            onClick={() => (setDay(2))}
          >
            {isMobile ? "Saturday Jan 27" : "Saturday, January 27" }
          </DaySelector>
          <DaySelector 
            selected={day == 3} 
            mobile={isMobile} 
            onClick={() => (setDay(3))}
          >
            {isMobile ? "Sunday Jan 28" : "Sunday, January 28" }
          </DaySelector>
        </div>
        <div style={{display: isMobile ? "none" : "block"}}>
          <EventHeader colour={"#282828"}>
            <EventHeaderInfo width="250px" align="left">Event</EventHeaderInfo>
            <EventHeaderInfo width="100px" align="center">Time</EventHeaderInfo>
            <EventHeaderInfo width="200px" align="right">Room</EventHeaderInfo>
          </EventHeader>
        </div>
        {day == 1 
          ? fridayEvents
          : day == 2 
            ? saturdayEvents
            : sundayEvents
        }
        <div style={{marginTop: "40px", display: isMobile ? "none" : "flex", gap: "48px"}}>
          {eventLegend}
        </div>
      </div>
    </MainContainer>
  );
};

export { Schedule };
