import React from "react";

import {
  Label,
  Option,
  StyledInterLabel,
  StyledInterOption,
  ErrorLabel,
  ErrorText,
  OptionBox,
  OptionClick,
  OptionLabel,
  RequiredStarStyle,
} from "./index.styles";
import {
  setAppHackathonAttended,
  setAppTCInPerson,
  setAppTCMLH,
  setAppTCSubscribe,
  RootState,
  setAttending,
} from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";

interface Props {
  type?: string;
  id: string;
  title?: string;
  style?: any;
  isError?: boolean;
  errorLabel?: string;
  placeholder?: string;
  required?: boolean;
  value?: string;
  options: Array<Map<string, any>>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  requiredLabelStar?: boolean;
  disabled?: boolean;
}

const toggleClick = (id?: string) => {
  let click = document.getElementById(id + "-click") as HTMLElement;
  let option = document.getElementById(id + "-option") as HTMLInputElement;
  if (click && option) {
    if (option.checked) {
      if (option.type !== "radio") {
        option.checked = false;
      }
    } else {
      option.checked = true;
    }
  }
};

const InputOption = ({
  id,
  type,
  title,
  isError,
  errorLabel,
  required,
  options,
  requiredLabelStar,
  onChange,
  disabled = false,
}: Props) => {
  const dispatch = useDispatch();
  const { app_hackathon_attended } = useSelector(
    (state: RootState) => state.application
  );

  const { attending } = useSelector((state: RootState) => state.rsvp);

  return (
    <div style={{ marginTop: "30px" }}>
      {requiredLabelStar ? (
        <Label htmlFor={id}>
          <StyledInterLabel>
            {title}
            <RequiredStarStyle>*</RequiredStarStyle>
          </StyledInterLabel>
        </Label>
      ) : (
        <Label htmlFor={id}>
          <StyledInterLabel>{title}</StyledInterLabel>
        </Label>
      )}
      <StyledInterOption isError={isError}>
        {options.map((option) => (
          <OptionLabel
            htmlFor={option.get("value")}
            onClick={
              disabled
                ? () => {}
                : () => {
                    toggleClick(option.get("value"));

                    switch (option.get("value")) {
                      case "in-person":
                        dispatch(setAppTCInPerson(!option.get("checked")));
                        break;
                      case "mlh-coc":
                        dispatch(setAppTCMLH(!option.get("checked")));
                        break;
                      case "subscribe":
                        dispatch(setAppTCSubscribe(!option.get("checked")));
                        break;
                      case "yes":
                        if (!app_hackathon_attended) {
                          dispatch(setAppHackathonAttended(true));
                        }
                        break;
                      case "no":
                        if (app_hackathon_attended) {
                          dispatch(setAppHackathonAttended(false));
                        }
                        break;
                      case "yesRSVP":
                        if (!attending) {
                          dispatch(setAttending(true));
                        }
                        break;
                      case "noRSVP":
                        if (attending) {
                          dispatch(setAttending(false));
                        }
                        break;
                      default:
                        break;
                    }
                  }
            }
          >
            <OptionBox type={type} disabled={disabled}>
              <Option
                type={type}
                id={option.get("value") + "-option"}
                name={id}
                checked={Boolean(option.get("checked"))}
                required={required}
              />
              <OptionClick
                type={type}
                id={option.get("value") + "-click"}
                onChange={onChange}
              />
            </OptionBox>
            <span
              style={{
                width: "calc(100% - 25px)",
                cursor: disabled ? "not-allowed" : "pointer",
              }}
            >
              {option.get("text")}
            </span>
          </OptionLabel>
        ))}
      </StyledInterOption>
      <ErrorLabel isError={isError}>
        <img src="/assets/icons/error.svg" alt="error" />
        <ErrorText>{errorLabel}</ErrorText>
      </ErrorLabel>
    </div>
  );
};

export default InputOption;
