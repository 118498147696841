import React from "react";
import { ButtonStyle, ButtonText, StyledImage } from "./index.styles";

type ButtonProps = {
  color: string;
  image?: string;
  title: string;
  disabled?: boolean;
  onClick?: () => void;
};

const HomeButton: React.FC<ButtonProps> = ({
  color,
  image,
  title,
  onClick,
  disabled,
  ...props
}) => {
  return (
    <ButtonStyle
      color={color}
      disabled={disabled}
      {...props}
      onClick={disabled ? () => {} : onClick}
    >
      {disabled ? (
        <StyledImage src={"/assets/home-page/locked.svg"} alt={title} />
      ) : (
        image && <StyledImage src={image} alt={title} />
      )}
      <ButtonText>{title}</ButtonText>
    </ButtonStyle>
  );
};

export { HomeButton };
