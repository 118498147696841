const StarSVG = () => {
  return (
    <svg
      width="112"
      height="116"
      viewBox="0 0 112 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_46_74)">
        <path
          d="M107 56C65.0026 60.9665 60.9665 65.0026 56 107C51.0335 65.0026 46.9974 60.9665 5 56C46.9974 51.0335 51.0335 46.9974 56 5C60.9665 46.9974 65.0026 51.0335 107 56Z"
          fill="#F0C800"
        />
        <path
          d="M107 56C65.0026 60.9665 60.9665 65.0026 56 107C51.0335 65.0026 46.9974 60.9665 5 56C46.9974 51.0335 51.0335 46.9974 56 5C60.9665 46.9974 65.0026 51.0335 107 56Z"
          stroke="#242424"
          stroke-miterlimit="10"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_46_74"
          x="0.742432"
          y="0.742432"
          width="110.515"
          height="114.515"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.141176 0 0 0 0 0.141176 0 0 0 0 0.141176 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_46_74"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_46_74"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default StarSVG;
