import React from "react";
import { useSelector } from "react-redux";
import {
  RootState,
} from "../../../redux/store";
import { PassportPage } from "./index.styles";
import { StampContainer, StampSponsor1, StampSponsor2, StampSponsor3 } from "./stamps.styles";

const ProfPage5 = React.memo(
  ({
    show,
    isMobile,
    isDrawerOpen,
    stamp_coll
  }: {
    show: boolean;
    isMobile: boolean | null;
    isDrawerOpen?: boolean | null;
    stamp_coll: string
  }) => {
    const {
    } = useSelector((state: RootState) => state.application);

    return (
      <PassportPage id="page-5" show={show}>
        <StampContainer>
        <StampSponsor2
            src={stamp_coll.includes("rbc") ? "/assets/profile/stamps/UofTHacks_sponsor_workshop_2.svg" : "/assets/profile/stamps/UofTHacks_sponsor_workshop_2_empty.svg"} 
            alt="stamp applied"
            mobile={isMobile} 
            isDrawerOpen={isDrawerOpen} 
          />
          <div style={{
            display: "flex", 
            width: "100%",
            justifyContent: "right",
            alignItems: "center",
            height: "10px",
          }}>
            <StampSponsor3
              src={stamp_coll.includes("modiface") ? "/assets/profile/stamps/UofTHacks_sponsor_workshop_3.svg" : "/assets/profile/stamps/UofTHacks_sponsor_workshop_3_empty.svg"} 
              alt="stamp accepted" 
              mobile={isMobile} 
              isDrawerOpen={isDrawerOpen} 
            />
          </div>
          <StampSponsor1
            src={stamp_coll.includes("cohere") ? "/assets/profile/stamps/UofTHacks_sponsor_workshop_1.svg" : "/assets/profile/stamps/UofTHacks_sponsor_workshop_1_empty.svg"} 
            alt="stamp rsvp" 
            mobile={isMobile} 
            isDrawerOpen={isDrawerOpen} 
          />
        </StampContainer>
      </PassportPage>
    );
  }
);

export default ProfPage5;

