import Inter from "../inter";
import styled from "styled-components";

interface Props {
  text: string;
  href?: string;
}

const StyledInter = styled(Inter)`
  font-size: 14px;
  text-align: left;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
  display: inline;
  color: var(--Default-Stroke, #007d7d);
`;

const StyledLink = styled.a`
  color: #007d7d;

  text-align: right;
  leading-trim: both;

  text-edge: cap;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
`;

const Link = (props: Props) => {
  return (
    <StyledInter>
      <StyledLink href={props.href}>{props.text}</StyledLink>
    </StyledInter>
  );
};

export default Link;
