import React, { useEffect, useRef, useState } from "react";
import Input from "../input-box";
import {
  Label,
  StyledInterLabel,
  DropdownContainer,
  DropdownHeader,
  DisplayText,
  DropdownList,
  ErrorLabel,
  ErrorText,
  ArrowImage,
  DropdownItem,
  RequiredStarStyle,
} from "./index.styles";

type DropdownProps = {
  options: string[];
  id: string;
  title: string;
  placeholder?: string;
  value: string;
  isError?: boolean;
  isTextError?: boolean;
  errorLabel?: string;
  onChange: (val: string) => void;
  requiredStar?: boolean;
  otherPlaceholder?: string;
  hideOther?: boolean;
  disabled?: boolean;
};

const SelectComponent: React.FC<DropdownProps> = ({
  options,
  id,
  title,
  placeholder = "-Select-",
  value,
  isError,
  isTextError,
  errorLabel,
  onChange,
  requiredStar,
  otherPlaceholder = "Enter your answer here",
  hideOther = false,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [selectedOption, setSelectedOption] = useState(value);
  const [isErrorSelect, setIsErrorSelect] = useState<boolean | undefined>(true);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (option === "Other") {
      onChange("Other - ");
      return;
    }
    onChange(option);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsErrorSelect(false);
    // close it on mount. We set it to true initially so that the list can get rendered.
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(false);
    }, 1);
  }, []);

  useEffect(() => {
    if (selectedOption === "" && value) {
      setSelectedOption(value);
    }
  }, [value]);

  return (
    <>
      <DropdownContainer ref={dropdownRef}>
        {requiredStar ? (
          <Label htmlFor={id}>
            <StyledInterLabel>
              {title}
              <RequiredStarStyle>*</RequiredStarStyle>
            </StyledInterLabel>
          </Label>
        ) : (
          <Label htmlFor={id}>
            <StyledInterLabel>{title}</StyledInterLabel>
          </Label>
        )}
        <DropdownHeader
          onClick={disabled ? () => {} : toggleDropdown}
          isOpen={isOpen}
          isError={isErrorSelect || isError || selectedOption === "Other - "}
          disabled={disabled}
        >
          <DisplayText>{selectedOption || placeholder}</DisplayText>
          <ArrowImage
            src={
              isOpen
                ? "assets/application/arrow-up.svg"
                : "assets/application/arrow-down.svg"
            }
            alt={isOpen ? "up arrow" : "down arrow"}
          />
        </DropdownHeader>
        <DropdownList isOpen={isOpen}>
          {options.map((option, index) => (
            <DropdownItem
              key={index}
              onClick={() => handleOptionClick(option)}
              selected={option === value}
            >
              <DisplayText>{option}</DisplayText>
            </DropdownItem>
          ))}
          <DropdownItem
            key={options.length}
            onClick={() => handleOptionClick("Other")}
          >
            <DisplayText>{"Other"}</DisplayText>
          </DropdownItem>
        </DropdownList>
      </DropdownContainer>
      {!hideOther && (
        <div style={{ display: selectedOption === "Other" ? "block" : "none" }}>
          <Input
            id={id + "-other"}
            type="text"
            errorLabel="Sorry, you have entered incorrect data."
            placeholder={otherPlaceholder}
            // value={app_city}
            isError={isTextError}
            onChange={(e) => onChange("Other - " + e.target.value)}
            disabled={disabled}
          />
        </div>
      )}
      <ErrorLabel isError={isError || selectedOption === "Other - "}>
        <img src="/assets/icons/error.svg" alt="error" />
        <ErrorText>{errorLabel}</ErrorText>
      </ErrorLabel>
    </>
  );
};

export default SelectComponent;
