import styled, { keyframes } from "styled-components";
import Subjectivity from "../../subjectivity";
import Inter from "../../inter";
import InterSemiBold from "../../inter-semi-bold";

type HomePageContentProps = {
  isDrawerOpen?: boolean;
  mobile?: boolean | null;
};

const StyledSubjectivity = styled(Subjectivity)<HomePageContentProps>`
  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;
  font-family: Subjectivity;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.72px;
  font-size: ${({ mobile }) => (mobile ? "36px" : "48px")};
  display: flex;
  justify-content: flex-start;
  align-items: left;
  text-align: left;
`;

const StyledInterSemiBold = styled(InterSemiBold)<HomePageContentProps>`
  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;
  text-align: left;
  font-family: InterSemiBold;
  font-size: ${(props) => (props.isMobile ? "16px" : "24px")};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
`;

const StyledInter = styled(Inter)<HomePageContentProps>`
  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: ${(props) => (props.isMobile ? "14px" : "20px")};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 3rem;
  margin-top: 10px;
`;

const StyledImage = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 20px;
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
  }

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1; 
  }
`;

const MainContainer = styled.div`
  animation: ${fadeIn} 0.7s ease-in-out;
`;

export {
  StyledSubjectivity,
  StyledInter,
  StyledInterSemiBold,
  StyledImage,
  MainContainer,
};
