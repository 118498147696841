import React from "react";
import DashboardComponent from "../../components/dashboard";

type DashboardProps = {
  showFAQ?: boolean;
  showProfile?: boolean;
  showRSVP?: boolean;
  showSchedule?: boolean;
};

const Dashboard = ({
  showFAQ,
  showProfile,
  showRSVP,
  showSchedule,
}: DashboardProps) => {
  return (
    <DashboardComponent
      showFAQ={showFAQ}
      showProfile={showProfile}
      showRSVP={showRSVP}
      showSchedule={showSchedule}
    />
  );
};

export default Dashboard;
