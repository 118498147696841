import Subjectivity from "../subjectivity";
import styled from "styled-components";

type LoginProps = {
  isMobile?: boolean | null;
};

const StyledH2 = styled(Subjectivity)<LoginProps>`
  text-shadow: none;
  font-size: ${(props) => (props.isMobile ? "24px" : "48px")};
  letter-spacing: ${(props) => (props.isMobile ? "-1px" : "-2.4px")};
  margin-top: ${(props) => (props.isMobile ? "0" : "1.9rem")};
  margin-bottom: 20px;
  color: var(--Default-Stroke, #282828);
`;

const LoginForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoginInfo = styled.div`
  width: 70%;
  margin-bottom: 30px;
`;

const ErrorBox = styled.div`
  display: flex;
  padding: 1rem;
  border-radius: 5px;
  color: #cb3101;
  background-color: #fdecea;
`;

const ErrorText = styled.span`
  height: 100%;
  display: inline;
  margin: auto 10px;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0 1rem 0;
  cursor: pointer;
  color: #282828;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

export {
  StyledH2,
  LoginForm,
  LoginInfo,
  ErrorBox,
  ErrorText,
  CheckBoxContainer,
};
