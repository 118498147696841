import React from "react";
import { useSelector } from "react-redux";
import {
  RootState,
} from "../../../redux/store";
import { PassportPage } from "./index.styles";
import { StampContainer, StampSwag, StampFuel, StampCheckedIn } from "./stamps.styles";

const ProfPage4 = React.memo(
  ({
    show,
    isMobile,
    isDrawerOpen,
    stamp_coll
  }: {
    show: boolean;
    isMobile: boolean | null;
    isDrawerOpen?: boolean | null;
    stamp_coll: string
  }) => {
    const {
    } = useSelector((state: RootState) => state.application);

    return (
      <PassportPage id="page-4" show={show}>
        <StampContainer>
          <StampSwag 
            src= {stamp_coll.includes("swag") ? "/assets/profile/stamps/UofTHacks_swag_pickup.svg" : "/assets/profile/stamps/UofTHacks_swag_pickup_empty.svg"}
            alt="stamp applied"
            mobile={isMobile} 
            isDrawerOpen={isDrawerOpen} 
          />
          <div style={{
            display: "flex", 
            width: "100%",
            justifyContent: "right",
            alignItems: "center",
            height: "10px",
          }}>
            <StampFuel 
              src={stamp_coll.includes("food") ? "/assets/profile/stamps/UofTHacks_hacker_fuel.svg" : "/assets/profile/stamps/UofTHacks_hacker_fuel_empty.svg"}
              alt="stamp accepted" 
              mobile={isMobile} 
              isDrawerOpen={isDrawerOpen} 
            />
          </div>
          <StampCheckedIn
            src={stamp_coll.includes("check-in") ? "/assets/profile/stamps/UofTHacks_checked_in.svg" : "/assets/profile/stamps/UofTHacks_checked_in_empty.svg"}
            alt="stamp rsvp" 
            mobile={isMobile} 
            isDrawerOpen={isDrawerOpen} 
          />
        </StampContainer>
      </PassportPage>
    );
  }
);

export default ProfPage4;
