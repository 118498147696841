import React from "react";
import { useMobileDetect } from "../../hooks/useMobileDetect";
import Button from "../button";
import Modal from "../modal";
import {
  CloseButton,
  DialogBoxBody,
  DialogBoxHeader,
  DialogBoxTitle,
  DialogBoxWrapper,
  ButtonContainer,
} from "./index.styles";

type DialogBoxProps = {
  isOpen: boolean;
  button1Text?: string;
  button2Text?: string;
  button1Colour?: string;
  button2Colour?: string;
  title: string;
  body: string;
  button1Click?: () => void;
  button2Click?: () => void;
  onClose: () => void;
  extraBodyContent?: React.ReactNode;
};

const DialogBox = React.memo(
  ({
    isOpen,
    button1Text,
    button2Text,
    button1Colour = "#282828",
    button2Colour = "blue",
    title,
    body,
    button1Click,
    button2Click,
    onClose,
    extraBodyContent,
  }: DialogBoxProps) => {
    const isMobile = useMobileDetect();
    return (
      <Modal isOpen={isOpen} closeModal={onClose}>
        <DialogBoxWrapper>
          <DialogBoxHeader>
            <DialogBoxTitle>{title}</DialogBoxTitle>
            <CloseButton
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              onClick={onClose}
            >
              <path
                d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                fill="#282828"
              />
            </CloseButton>
          </DialogBoxHeader>
          <DialogBoxBody>
            {body} {extraBodyContent}
          </DialogBoxBody>

          <ButtonContainer>
            {button1Text && (
              <Button
                text={button1Text}
                colour={button1Colour}
                onClick={button1Click}
                style={
                  typeof isMobile !== "undefined" && isMobile
                    ? { minWidth: "140px" }
                    : {}
                }
              />
            )}
            {button2Text && (
              <Button
                text={button2Text}
                colour={button2Colour}
                onClick={button2Click}
                style={
                  typeof isMobile !== "undefined" && isMobile
                    ? { minWidth: "140px" }
                    : {}
                }
              />
            )}
          </ButtonContainer>
        </DialogBoxWrapper>
      </Modal>
    );
  }
);

export default DialogBox;
