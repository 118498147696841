const legend = {
  "logistic": {
    "event": "Logistic Event",
    "colour": "#FFFFFF"
  },
  "sponsor": {
    "event": "Sponsor Event",
    "colour": "#FFD28E"
  },
  "mini": {
    "event": "Mini Event",
    "colour": "#E3A1E3"
  },
  "food": {
    "event": "Food",
    "colour": "#C0F9EB"
  },
};

const fridaySchedule = [
  {
    type: "logistic",
    name: "Hacker Registration & Team Formation",
    time: "4:00pm",
    location: "Myhal 1st Floor",
  },
  {
    type: "logistic",
    name: "Opening Ceremonies",
    time: "6:30pm",
    location: "MY150",
  },
  {
    type: "logistic",
    name: "Hacking Begins!",
    time: "7:30pm",
    location: "",
  },
  {
    type: "food",
    name: "Dinner",
    time: "7:30pm",
    location: "Myhal 1st Floor",
  },
  {
    type: "mini",
    name: "GitHub Copilot Mini-Event",
    time: "9:00pm",
    location: "MY150",
  },
  {
    type: "sponsor",
    name: "Cohere Workshop",
    time: "10:00pm",
    location: "MY150",
  },
  {
    type: "mini",
    name: "MLH Bob Ross Event",
    time: "11:00pm",
    location: "MY150",
  },
];

const saturdaySchedule = [
  {
    type: "mini",
    name: "Red Bull Pong",
    time: "12:00am",
    location: "Myhal 1st Floor",
  },
  {
    type: "mini",
    name: "Late Night Karaoke",
    time: "1:00am",
    location: "MY150",
  },
  {
    type: "food",
    name: "Snacks",
    time: "2:00am",
    location: "Myhal 1st Floor",
  },
  {
    type: "food",
    name: "Breakfast",
    time: "9:00am",
    location: "Myhal 1st Floor",
  },
  {
    type: "sponsor",
    name: "Sponsor Booths",
    time: "10:00am",
    location: "Myhal 2nd Floor",
  },
  {
    type: "food",
    name: "Lunch",
    time: "12:00pm",
    location: "Myhal 1st Floor",
  },
  {
    type: "sponsor",
    name: "RBC GenAI Workshop",
    time: "1:00pm",
    location: "MY150",
  },
  {
    type: "sponsor",
    name: "ModiFace Workshop: Let’s Dive into Beauty AR Tech!",
    time: "2:00pm",
    location: "MY150",
  },
  {
    type: "mini",
    name: "P&G Trivia Mini Event",
    time: "3:00pm",
    location: "TBD",
  },
  {
    type: "sponsor",
    name: "Ubisoft Workshop - AI Programming in Game Development",
    time: "4:00pm",
    location: "MY150",
  },
  {
    type: "sponsor",
    name: "Retro Arcade Games",
    time: "5:00pm",
    location: "Myhal 1st Floor",
  },
  {
    type: "mini",
    name: "Soroban Smart Contract Mini Event",
    time: "6:00pm",
    location: "MY150",
  },
  {
    type: "food",
    name: "Dinner",
    time: "7:00pm",
    location: "Myhal 1st Floor",
  },
  {
    type: "mini",
    name: "Moonshine Gaming Mini Event",
    time: "9:00pm",
    location: "MY150",
  },
  {
    type: "mini",
    name: "Hot Noodle Challenge",
    time: "10:00pm",
    location: "Myhal 1st Floor",
  },
  {
    type: "mini",
    name: "Cup Stacking",
    time: "11:00pm",
    location: "Myhal 1st Floor",
  },
];

const sundaySchedule = [
  {
    type: "food",
    name: "Pizza",
    time: "1:00am",
    location: "Myhal 1st Floor",
  },
  {
    type: "mini",
    name: "Jackbox Games",
    time: "2:00am",
    location: "MY150",
  },
  {
    type: "logistic",
    name: "Hacking Ends!",
    time: "7:30am",
    location: "",
  },
  {
    type: "logistic",
    name: "Judging",
    time: "9:00am",
    location: "MY150",
  },
  {
    type: "logistic",
    name: "Closing Ceremonies",
    time: "1:00pm",
    location: "MY150",
  }
];

export {
  legend,
  fridaySchedule, 
  saturdaySchedule, 
  sundaySchedule
}
