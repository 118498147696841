import React, { useEffect, useState } from "react";
import Drawer from "./drawer";
import Content from "./content";
import { useMobileDetect } from "../../hooks/useMobileDetect";

type DashboardProps = {
  showFAQ?: boolean;
  showSchedule?: boolean;
  showProfile?: boolean;
  showRSVP?: boolean;
};

const DashboardComponent: React.FC<DashboardProps> = ({
  showFAQ,
  showProfile,
  showRSVP,
  showSchedule,
}) => {
  const isMobile = useMobileDetect();
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    setDrawerOpen(!isMobile);
  }, [isMobile]);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <Drawer isOpen={isDrawerOpen} onClose={toggleDrawer} />
      <Content
        isDrawerOpen={isDrawerOpen}
        onToggleDrawer={toggleDrawer}
        showFAQ={showFAQ}
        showProfile={showProfile}
        showRSVP={showRSVP}
        showSchedule={showSchedule}
      />
    </>
  );
};

export default DashboardComponent;
