import styled from "styled-components";
import Subjectivity from "../subjectivity";

type StyledDashboardProps = {
  mobile?: boolean | null;
  isQuiz?: boolean;
  goHome?: boolean;
};

const StyledDashboard = styled.p<StyledDashboardProps>`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  min-width: 100%;
  min-height: 100vh;
  padding-bottom: 50px;
  align-content: center;
  background-color: ${(props) =>
    props.isQuiz && !props.mobile
      ? "#ecd8ec"
      : props.mobile
      ? "#F9F9F9"
      : "#E1EBFF"};
`;

const InfoBoxContainer = styled.div<StyledDashboardProps>`
  width: 100%;
  background-color: #f9f9f9;
  border: ${(props) => (props.mobile ? "none" : "1px solid #000000")};
  border-radius: 20px;
  box-shadow: ${(props) => (props.mobile ? "none" : "0px 4px #000000")};
  padding: ${(props) => (props.mobile ? "0" : "3rem")};
  max-width: ${(props) => (props.mobile ? "100%" : "67vw")};
  position: relative;
`;

const ColourText = styled.span<StyledDashboardProps>`
  ${(props) => (props.goHome ? "cursor: pointer;" : "")}
  color: ${(props) =>
    props.isQuiz
      ? "#F0C800"
      : props.mobile
      ? "var(--pink, #F0A0C8)"
      : "var(--teal, #007D7D)"};
`;

const Image = styled.div<StyledDashboardProps>`
  display: ${(props) => (props.mobile ? "none" : "block")};
  position: absolute;
  top: -25px;
  right: -25px;
  object-fit: contain;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
`;

const StyledSubjectivity = styled(Subjectivity)<StyledDashboardProps>`
  margin: ${(props) => (props.mobile ? "2rem 0" : "1.25rem 0")};
  letter-spacing: -4px;
  text-align: left;
  padding: 0.62rem;
  text-shadow: 0px 4px 0px #282828;
  -webkit-text-stroke: 1px #282828;
`;

export {
  StyledDashboard,
  InfoBoxContainer,
  ColourText,
  Image,
  StyledSubjectivity,
};
