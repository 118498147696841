import React, { useEffect, useState } from "react";
import styled from "styled-components";

type EditIconProps = {
  isHovered: boolean;
};

const Wrapper = styled.div`
  height: 24px;
  width: 24px;
`;

const Icon = styled.svg<EditIconProps>`
  width: 100%;
  height: 100%;
  cursor: pointer;
  fill: ${(props) => (props.isHovered ? "#2850A0" : "#f9f9f9")};
  transition: 0.3s ease-out all;
`;

export const EditIcon = ({ isHovered }: EditIconProps) => {
  const [hovered, setHovered] = useState(true);
  useEffect(() => {
    setHovered(isHovered);
  }, [isHovered]);

  return (
    <Wrapper>
      <Icon
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        isHovered={hovered}
      >
        <path d="M14.5 22V18.925L20.025 13.425C20.175 13.275 20.3417 13.1667 20.525 13.1C20.7083 13.0333 20.8917 13 21.075 13C21.275 13 21.4667 13.0375 21.65 13.1125C21.8333 13.1875 22 13.3 22.15 13.45L23.075 14.375C23.2083 14.525 23.3125 14.6917 23.3875 14.875C23.4625 15.0583 23.5 15.2417 23.5 15.425C23.5 15.6083 23.4667 15.7958 23.4 15.9875C23.3333 16.1792 23.225 16.35 23.075 16.5L17.575 22H14.5ZM16 20.5H16.95L19.975 17.45L19.525 16.975L19.05 16.525L16 19.55V20.5ZM6.5 22C5.95 22 5.47917 21.8042 5.0875 21.4125C4.69583 21.0208 4.5 20.55 4.5 20V4C4.5 3.45 4.69583 2.97917 5.0875 2.5875C5.47917 2.19583 5.95 2 6.5 2H14.5L20.5 8V11H18.5V9H13.5V4H6.5V20H12.5V22H6.5ZM19.525 16.975L19.05 16.525L19.975 17.45L19.525 16.975Z" />
      </Icon>
    </Wrapper>
  );
};
