import React, { useState } from "react";
import Input from "../input-box";
import Button from "../button";
import Link from "../link";
import { useMobileDetect } from "../../hooks/useMobileDetect";
import DashboardContainer from "../dashboard-container";
import {
  StyledH2,
  LoginForm,
  LoginInfo,
  ErrorBox,
  ErrorText,
  CheckBoxContainer,
} from "./index.styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setAuthTokenRedux,
  setIsAuthenticatedRedux,
  setUserID,
  setUserFName,
  setUserLName,
  setCharacterName,
  setJWTExp,
  setAppCompleted,
  setReduxCharacterName,
  setRefreshToken,
  setDidRSVP,
  setIsAccepted,
  setIsRound2,
} from "../../redux/store";
import LoadingCircleComponent from "../loading-component";
import { API_BASE_URL, API_TYPE, API_VERSION } from "../../api/apiConfig";
import { Option, OptionBox, OptionClick } from "../input-option/index.styles";

export const decodeJWTWithoutLibrary = (token: string) => {
  try {
    const tokenParts = token.split(".");
    const decodedHeader = atob(tokenParts[0]);
    const decodedPayload = atob(tokenParts[1]);
    const decodedSignature = tokenParts[2];
    return {
      // header: JSON.parse(decodedHeader),
      payload: JSON.parse(decodedPayload),
      // signature: decodedSignature,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const LoginComponent = () => {
  const isMobile = useMobileDetect();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailError, setEmailError] = useState(false);
  const [isPasswordError, setPasswordError] = useState(false);
  const [keepMeLoggedIn, setKeepMeLoggedIn] = useState(false);

  const [authError, setAuthError] = useState(false);
  const [authErrorText, setAuthErrorText] = useState("Error");
  const [resentVerification, setResentVerification] = useState(false);

  // const { isAuthenticated } = useSelector((state: RootState) => state.data);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleLogin = () => {
    if (email && password) {
      setLoading(true);
      fetch(`${API_BASE_URL}/${API_VERSION}/${API_TYPE.USER}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_email: email,
          user_password: password,
          mg_token: keepMeLoggedIn,
        }),
      })
        .then((response) => {
          if (response.ok) {
            setAuthError(false);
            response.json().then((data) => {
              dispatch(setAuthTokenRedux(data.token));
              dispatch(setRefreshToken(data.refresh));
              const userObject = decodeJWTWithoutLibrary(data.token);
              if (userObject) {
                dispatch(setUserID(userObject.payload.user_id));
                dispatch(setUserFName(userObject.payload.user_fname));
                dispatch(setUserLName(userObject.payload.user_lname));
                dispatch(setCharacterName(userObject.payload.user_personality));
                dispatch(
                  setReduxCharacterName(userObject.payload.user_personality)
                );
                dispatch(setJWTExp(userObject.payload.exp));
                dispatch(setAppCompleted(userObject.payload.app_submitted));
                dispatch(setIsAuthenticatedRedux(true));

                fetch(
                  `${API_BASE_URL}/${API_VERSION}/${API_TYPE.APP}/getuserstatus/ch3ckth3s7a7s`,
                  {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${data.token}`,
                    },
                  }
                ).then((response) => {
                  if (response.ok) {
                    response.json().then((data) => {
                      dispatch(setDidRSVP(data.has_rsvped));
                      dispatch(setIsAccepted(data.is_accepted === 1));
                      dispatch(setIsRound2(data.round2 === 1));
                    });
                  }
                });
              }
            });
            setLoading(false);
          } else {
            setAuthError(true);
            setResentVerification(false);
            response.json().then((data) => {
              setAuthErrorText(data.message);
            });
            setLoading(false);
          }
        })
        .catch((error) => {
          setAuthError(true);
          setAuthErrorText(
            "Something doesn't look right on our end. Please try again later."
          );
          console.error(error);
          setLoading(false);
        });
    } else {
      if (!email) setEmailError(true);
      else setEmailError(false);
      if (!password) setPasswordError(true);
      else setPasswordError(false);
    }
  };

  return (
    <DashboardContainer>
      <LoadingCircleComponent isOpen={loading} />
      <StyledH2 isMobile={isMobile}>Login</StyledH2>

      <LoginForm
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {authError && (
          <ErrorBox>
            <img src="/assets/icons/error.svg" alt="error" />
            <ErrorText>
              {authErrorText ===
              "Please check your email to verify your account!" ? (
                <>
                  Unfortunately, we are not accepting new applications as the
                  registration for UofTHacks 11 has now closed. Additionally,
                  your account has not been verified. We appreciate your
                  interest and hope to see you in the next event. Thank you!
                </>
              ) : (
                authErrorText
              )}
              {/* {authErrorText ===
                "Please check your email to verify your account!" && (
                <>
                  {" "}
                  <span
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={
                      resentVerification
                        ? () => {}
                        : () => {
                            setLoading(true);
                            fetch(
                              `${API_BASE_URL}/${API_VERSION}/${API_TYPE.USER}/verifyupdate`,
                              {
                                method: "POST",
                                headers: {
                                  "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                  user_email: email,
                                }),
                              }
                            )
                              .then(() => {
                                setLoading(false);
                                setResentVerification(true);
                                setAuthErrorText(
                                  "A verification email has been resent to your email address!"
                                );
                              })

                              .catch((error) => {
                                setAuthError(true);
                                setAuthErrorText(
                                  "Something doesn't look right on our end. Please try again later."
                                );
                                console.error(error);
                                setLoading(false);
                              });
                          }
                    }
                  >
                    Resend Verification Email?
                  </span>
                </>
              )} */}
            </ErrorText>
          </ErrorBox>
        )}
        <LoginInfo onSubmit={handleLogin}>
          <Input
            id="email"
            title="Email Address"
            type="email"
            placeholder="Enter your email"
            errorLabel="Please enter your email."
            required
            isError={isEmailError}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError(false);
            }}
            value={email}
          />
          <Input
            id="password"
            title="Password"
            type="password"
            errorLabel="Please enter your password."
            placeholder="Enter your password"
            isError={isPasswordError}
            required
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordError(false);
            }}
            value={password}
            style={{
              color: "#282828",
            }}
          />
          <Link text="Forgot your Password?" href="/reset-password" />

          <CheckBoxContainer
            onClick={() => {
              setKeepMeLoggedIn(!keepMeLoggedIn);
            }}
          >
            <OptionBox type={"checkbox"}>
              <Option
                type={"checkbox"}
                name={"Logged In"}
                checked={keepMeLoggedIn}
              />
              <OptionClick type={"checkbox"} />
            </OptionBox>
            <span style={{ marginLeft: "0.5rem" }}>
              Keep me logged in. Please uncheck if you are using a public device
            </span>
          </CheckBoxContainer>
        </LoginInfo>
        <Button text="LOGIN" colour="blue" onClick={handleLogin} />
        <LoginInfo
          style={{
            justifyContent: "center",
            textAlign: "center",
            marginTop: "2rem",
            marginBottom: "0rem",
          }}
        >
          {/* Don't have an account?{" "}
          <Link text="Create account here" href="/signup" /> */}
          Hacker Applications for UofTHacks 11 are now closed. Thank you for
          your interest!
        </LoginInfo>
      </LoginForm>
    </DashboardContainer>
  );
};

export default LoginComponent;
